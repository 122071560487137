import React from 'react';
import { ValidationResult } from 'components/bricks/types/validator.type';
import Translation from 'components/data/Translation';

import './../styles/alert-tooltip-content.scss';

interface Props {
    warnings?: ValidationResult[];
    errors?: ValidationResult[];
    success?: ValidationResult[];
    info?: ValidationResult[];
}

const TooltipContent: React.FC<Props> = ({ errors, warnings, success, info }) => {
    return (
        <div className="ad-setup__tooltip__content">
            {!!errors?.length && (
                <div className="ad-setup__tooltip__content__section">
                    <span className="ad-setup__tooltip__content__section__title">{Translation.get('adSetup.alertTooltipContent.errors', 'bricks')}:</span>
                    {errors?.map((error, index) => {
                        return (
                            <div key={index} className="ad-setup__tooltip__content__section__item">
                                <span className="ad-setup__tooltip__content__section__item__point ad-setup__tooltip__content__section__item__point--error"></span>
                                <span className="ad-setup__tooltip__content__section__item__copy">{error.message}</span>
                            </div>
                        );
                    })}
                </div>
            )}

            {!!warnings?.length && (
                <div className="ad-setup__tooltip__content__section">
                    <span className="ad-setup__tooltip__content__section__title">{Translation.get('adSetup.alertTooltipContent.warnings', 'bricks')}:</span>
                    {warnings?.map((warning, index) => {
                        return (
                            <div key={index} className="ad-setup__tooltip__content__section__item">
                                <span className="ad-setup__tooltip__content__section__item__point ad-setup__tooltip__content__section__item__point--warning"></span>
                                <span className="ad-setup__tooltip__content__section__item__copy">{warning.message}</span>
                            </div>
                        );
                    })}
                </div>
            )}

            {!!success?.length && !errors?.length && !warnings?.length && (
                <div className="ad-setup__tooltip__content__section">
                    <span className="ad-setup__tooltip__content__section__title">{Translation.get('adSetup.alertTooltipContent.success', 'bricks')}:</span>
                    {success?.map((success, index) => {
                        return (
                            <div key={index} className="ad-setup__tooltip__content__section__item">
                                <span className="ad-setup__tooltip__content__section__item__point ad-setup__tooltip__content__section__item__point--success"></span>
                                <span className="ad-setup__tooltip__content__section__item__copy">{success.message}</span>
                            </div>
                        );
                    })}
                </div>
            )}

            {!!info?.length && !errors?.length && !warnings?.length && (
                <div className="ad-setup__tooltip__content__section">
                    <span className="ad-setup__tooltip__content__section__title">{Translation.get('adSetup.alertTooltipContent.info', 'bricks')}:</span>
                    {info?.map((info, index) => {
                        return (
                            <div key={index} className="ad-setup__tooltip__content__section__item">
                                <span className="ad-setup__tooltip__content__section__item__point ad-setup__tooltip__content__section__item__point--info"></span>
                                <span className="ad-setup__tooltip__content__section__item__copy">{info.message}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default TooltipContent;
