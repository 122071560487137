import React, { useRef, useState } from 'react';
import IconButton from 'components/ui-components-v2/IconButton';
import { defaultStyles, FileIcon } from 'react-file-icon';
import FileUpload from 'components/data/FileUpload';
import Icon from 'components/ui-components-v2/Icon';
import { Brick, BrickBriefingAttachment } from 'components/bricks/types/brick.type';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import { MODEL_BRIEFING_ATTACHMENTS } from 'components/bricks/constants';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';

import '../styles/attachments.scss';

interface UploadedFileProps {
    fileName: string;
    fileUrl: string;
    onRemove: (url: string) => void;
}

// Uploaded file component to show a file that has been uploaded to the briefing of a brick
const UploadedFile = ({ fileUrl, fileName, onRemove }: UploadedFileProps) => {
    const fileExtension = fileName.toLowerCase().split('.')[1];
    let fileIconSpecs = {};
    if (defaultStyles[fileExtension]) {
        fileIconSpecs = defaultStyles[fileExtension];
    }

    return (
        <div className="briefing-attachments__uploaded-file">
            <div className="briefing-attachments__uploaded-file__icon">
                <FileIcon extension={fileExtension} {...fileIconSpecs} />
            </div>

            <div className="briefing-attachments__uploaded-file__info">
                <div className="briefing-attachments__uploaded-file__info__name">{fileName}</div>
                <a className="briefing-attachments__uploaded-file__info__download" href={fileUrl} target="_blank" rel="noreferrer">
                    Download
                </a>
            </div>
            <IconButton className="briefing-attachments__uploaded-file__close" size="small" onClick={() => onRemove(fileUrl)}>
                <Icon>close</Icon>
            </IconButton>
        </div>
    );
};

interface Props {
    brick: Brick;
}

/**
 * Attachments that can be added to a briefing of a brick. You can add images, pdfs and videos.
 */
const BriefingAttachments = ({ brick }: Props) => {
    const defaultAttachments: BrickBriefingAttachment[] = brick?.data?.briefing?.attachments ?? []; // Get the source data from the asset gallery dialog state.
    const [files, setFiles] = useState<BrickBriefingAttachment[]>(defaultAttachments);
    const sourceDataListRef = useRef<BrickBriefingAttachment[]>(defaultAttachments); // This is used to keep track on the item that already has been uploaded.

    // Add the uploaded files to the list of files
    const handleFileUploadComplete = (uploadedFiles: SourceData[]) => {
        const mappedFiles = uploadedFiles.map((file) => {
            return {
                url: file.url,
                fileName: file.fileName ?? ''
            };
        });

        const newItem = mappedFiles[0];

        sourceDataListRef.current = [...sourceDataListRef.current, newItem];
        setFiles(sourceDataListRef.current);

        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_BRIEFING_ATTACHMENTS, sourceDataListRef.current);
    };

    /**
     * Removes a file from the list of files
     * @param fileUrl url of the file to be removed
     */
    const onFileRemove = (fileUrl: string) => {
        const newFiles = files.filter((file) => file.url !== fileUrl);
        sourceDataListRef.current = newFiles; // Update the source data list ref with the new files.
        setFiles(newFiles);

        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_BRIEFING_ATTACHMENTS, newFiles);
    };

    return (
        <div className="briefing-attachments">
            {files.map((file, index) => (
                <UploadedFile key={`${index}-${file.fileName}`} fileUrl={file.url} fileName={file.fileName} onRemove={onFileRemove} />
            ))}
            <div>
                <FileUpload
                    classes={{ root: 'briefing-attachments__file-upload' }}
                    fileType={['image', 'pdf', 'video']}
                    onUploadComplete={handleFileUploadComplete}
                    size="small"
                    display="square"
                    multiple
                />
            </div>
        </div>
    );
};

export { BriefingAttachments };
