import { AssetRestrictionsKeys } from 'components/bricks/types/preset';

export interface DefaultAssetRestrictionKeys {
    image: AssetRestrictionsKeys[];
    video: AssetRestrictionsKeys[];
    audio: AssetRestrictionsKeys[];
    zip: AssetRestrictionsKeys[];
}

/**
 * Default asset restriction keys.
 * These keys are used when an asset is not briefed.
 */
const defaultAssetRestrictionKeys: DefaultAssetRestrictionKeys = {
    image: ['fileExtension', 'width', 'height', 'maxSizeKb', 'aspectRatio'],
    video: ['fileExtension', 'width', 'height', 'maxSizeKb', 'aspectRatio'],
    audio: ['fileExtension'],
    zip: ['fileExtension', 'width', 'height']
};

export default defaultAssetRestrictionKeys;
