import React from 'react';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import classNames from 'classnames';
import Typography from 'components/ui-components-v2/Typography';

interface Props {
    title: string;
    children: ReactJSXElement;
    classes?: {
        root?: string;
        summaryRoot?: string;
        details?: string;
    };
    square?: boolean;
    disableGutters?: boolean;
    actions?: React.ReactNode; // The actions on the right of the header
}
const BrickAccordion: React.FC<Props> = ({ title, children, square, disableGutters, actions }) => {
    return (
        <Accordion defaultExpanded square={square} disableGutters={square}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{title}</Typography>
                <div>{actions}</div>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default BrickAccordion;
