import React, { useMemo, useState } from 'react';
import IconButton from 'components/ui-components-v2/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import classNames from 'classnames';
import { Alert } from '@mui/material';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import Translation from 'components/data/Translation';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import useValidations from 'components/bricks/hooks/useValidations';
import useBrick from 'components/bricks/hooks/useBrick';
import { AdSetupItem } from '../types/AdSetup.type';
import { useAdSetupContext } from '../context/ad-setup-context';
import './../styles/carousel-tabs.scss';

const MAX_TABS_COUNT = 10;

interface Props {
    children: React.ReactNode;
    identifier: string;
}

interface ComponentStoreProps {
    activeTab: BricksComponentStore['activeTab'];
}

const CarouselTabs: React.FC<Props> = ({ children, identifier }) => {
    const { activeTab } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: {
            activeTab: 'activeTab'
        }
    });

    const { brick } = useBrick();

    if (!brick) return;

    const { adSetup, selectedFrame, changeSelectedFrame, changeAdSetup } = useAdSetupContext();

    const { validations } = useValidations([brick.id], activeTab?.key, identifier);

    const [actionMenuOpen, setActionMenuOpen] = useState<(EventTarget & HTMLButtonElement) | undefined>();
    const [removeAlertOpen, setRemoveAlertOpen] = useState<boolean>(false);

    const frames: AdSetupItem[] = useMemo(() => {
        return adSetup.items || [{}];
    }, [adSetup]);

    const modifyFrames = (newFrames: AdSetupItem[]) => {
        const adSetupCopy = { ...adSetup };
        adSetupCopy.items = newFrames;

        changeAdSetup(adSetupCopy);
    };

    /**
     * Adds new frame
     */
    const addFrame = () => {
        // Puts the new frame in Editor data
        const framesCopy = [...frames];
        framesCopy.push({});

        modifyFrames(framesCopy);
        changeSelectedFrame(frames.length);
    };

    /**
     * Moves the tab left and right
     * @param event
     * @param direction
     */
    const handleMove = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, direction: number) => {
        event.stopPropagation();

        // Copies the frames from Editor data
        const framesCopy = [...frames];

        // Gets the indexes of the frame we want to move and the target frame
        const frameNrToMove = selectedFrame;
        const targetFrameNr = frameNrToMove + direction;

        // Copies the data of the frame we want to move and the target frame
        const frameDataToMove = framesCopy[frameNrToMove];
        const targetFrameData = framesCopy[targetFrameNr];

        // Switches the places of the frame we want to move and the target one
        framesCopy[frameNrToMove] = targetFrameData;
        framesCopy[targetFrameNr] = frameDataToMove;

        modifyFrames(framesCopy);

        changeSelectedFrame(targetFrameNr);
        setActionMenuOpen(undefined);
    };

    /**
     * handleActionMenuOpen
     * Show an event to handle menu bar that is opened.
     */
    const handleActionMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setActionMenuOpen(event.currentTarget);
        event.stopPropagation();
    };

    /**
     * Hide remove confirm dialog
     */
    const handleRemoveFrameDecline = () => {
        setRemoveAlertOpen(false);
    };

    /**
     * Removes a frame after confirmation
     */
    const handleRemoveFrameConfirm = () => {
        const framesCopy = [...frames];
        // Removes the frame
        framesCopy.splice(selectedFrame, 1);

        if (selectedFrame === frames.length - 1) changeSelectedFrame(selectedFrame - 1);

        modifyFrames(framesCopy);

        setRemoveAlertOpen(false);
    };

    /**
     * Remove frame
     * Removes the frame and all the associated data.
     */
    const removeFrame = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();
        setActionMenuOpen(undefined);
        setRemoveAlertOpen(true);
    };

    /**
     * Copies frame and creates new one with the copied frame data
     */
    const handleCopy = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();

        // Copies the frames
        const framesCopy = [...frames];

        // Data of copied frame
        const frameData = framesCopy[selectedFrame];

        framesCopy.push(frameData);

        modifyFrames(framesCopy);

        setActionMenuOpen(undefined);
        changeSelectedFrame(framesCopy.length - 1);
    };

    return (
        <React.Fragment>
            <div className="carousel-tabs">
                <ConfirmDialog
                    open={removeAlertOpen ? true : false}
                    title={Translation.get('adSetup.carouselTabs.confirmDialog.title', 'bricks')}
                    description={Translation.get('adSetup.carouselTabs.confirmDialog.description', 'bricks')}
                    onConfirm={handleRemoveFrameConfirm}
                    onClose={handleRemoveFrameDecline}
                />
                {frames &&
                    frames.map((frame, i) => (
                        <div
                            key={'tab-' + i}
                            className={classNames('carousel-tabs__item', {
                                'carousel-tabs__item--active': selectedFrame === i
                            })}
                            onClick={() => changeSelectedFrame(i)}>
                            <Menu
                                elevation={1}
                                keepMounted
                                anchorEl={actionMenuOpen}
                                open={Boolean(actionMenuOpen)}
                                onClose={() => setActionMenuOpen(undefined)}>
                                <MenuItem onClick={(event) => removeFrame(event)} disabled={frames.length === 1}>
                                    {Translation.get('adSetup.carouselTabs.remove', 'bricks')}
                                </MenuItem>
                                <MenuItem onClick={(event) => handleCopy(event)}>{Translation.get('adSetup.carouselTabs.copy', 'bricks')}</MenuItem>
                                <MenuItem onClick={(event) => handleMove(event, 1)} disabled={selectedFrame === frames.length - 1}>
                                    {Translation.get('adSetup.carouselTabs.moveRight', 'bricks')}
                                </MenuItem>
                                <MenuItem onClick={(event) => handleMove(event, -1)} disabled={selectedFrame === 0}>
                                    {Translation.get('adSetup.carouselTabs.moveLeft', 'bricks')}
                                </MenuItem>
                            </Menu>
                            <div className="carousel-tabs__item__title">{i + 1}</div>
                            {selectedFrame === i && (
                                <IconButton color="primary" size="small" className={'carousel-tabs__item__more'} onClick={(e) => handleActionMenuOpen(e)}>
                                    <MoreVertIcon fontSize="inherit" />
                                </IconButton>
                            )}
                        </div>
                    ))}

                {adSetup.items && adSetup.items?.length < MAX_TABS_COUNT && (
                    <div key={'tab-add'} className={'carousel-tabs__item__add'} onClick={() => addFrame()}>
                        <Tooltip title={Translation.get('adSetup.carouselTabs.addFrame', 'bricks')}>
                            <IconButton size="small">
                                <AddCircleIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
            {validations?.map((error, id) => (
                <Alert className="carousel-tabs__errors" key={id} severity={'info'}>
                    {error.message}
                </Alert>
            ))}

            <div className="carousel-tabs__children">{children}</div>
        </React.Fragment>
    );
};
export default CarouselTabs;
