import React, { useEffect, useState } from 'react';
import Format from 'types/format.type';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Placement, PlacementsSection } from 'components/bricks/types/placement.type';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import useBrick from 'components/bricks/hooks/useBrick';
import { useAdSetupContext } from '../context/ad-setup-context';
import { AdSetupItemPlacement, AdSetupPlacements } from '../types/AdSetup.type';
import PlacementSection from './ad-placements-section';

export interface PlacementItemInterface extends Placement {
    creativeTitle?: string;
    creativeUrl?: string;
    formatName?: string;
    assetUrl?: string;
}

const AdPlacements = () => {
    const [placementSections, setPlacementSections] = useState<PlacementsSection[]>([]);

    const { brick } = useBrick();
    const { creatives, onChange } = useAdSetupContext();

    if (!brick) return;

    /**
     * Gets enriched creative
     * @param creative
     * @returns Enriched creative
     */
    const getCreative = async (creative): Promise<CreativeV2Enriched | undefined> => {
        try {
            if (creative) {
                // TODO: check if this should be done here or in the dynamicAssetV2
                return await CreativeV2Helpers.enrichCreative(creative);
            }
            return;
        } catch (error) {
            console.log('error', error);
        }
    };

    /**
     * Assings assets to placements by their dimensional requirenments
     * @param creatives
     * @returns object of ad setup placements
     */
    const getAssignedAssetsToPlacements = (enrichedCreatives: CreativeV2Enriched[]): AdSetupPlacements => {
        // Placements object for the brick type
        const placementsSectionsData = BrickHelpers.getBrickData(brick?.subType, 'placements') as PlacementsSection[];

        // Selected placements from meta ad set
        const parentBrick = BrickHelpers.getBrickById(brick.parentId);
        const selectedPlacements: string[] = parentBrick?.data?.settings?.targeting?.placements || [];

        // Loopsthorugh all placements to assign formats to them
        return placementsSectionsData.reduce((acc: AdSetupPlacements, section: PlacementsSection) => {
            section.children.forEach((placement) => {
                // Check if the current placement is included in parent
                if (selectedPlacements.length && !selectedPlacements.includes(placement.key)) return acc;

                // Gets appropriate format for placement
                const { creative, creativeFormatKey } = getClosestAsset(enrichedCreatives, placement);

                // Checks there is any creative suitable for that placement
                if (!creative) return acc;

                const placementKey = placement.key;

                const itemPlacement: AdSetupItemPlacement = {
                    formatKey: creativeFormatKey,
                    creativeId: creative.id
                };

                acc = { ...acc, [placementKey]: itemPlacement };
            });

            return acc;
        }, {});
    };

    /**
     * Get format that fits the most for a placement
     * @param enrichedCreatives
     * @param placement
     * @returns the creativeIndex and the formatIndex in the creative
     */
    const getClosestAsset = (enrichedCreatives: CreativeV2Enriched[], placement: Placement): { creative?: CreativeV2Enriched; creativeFormatKey?: string } => {
        let closestDiff: number | undefined;
        let creative: CreativeV2Enriched | undefined;
        let creativeFormatKey: string | undefined;
        const placementAspectRatio = placement.recommendedWidth / placement.recommendedHeight;
        const placementMinWidth = placement.minWidth || 0;
        const placementMinHeight = placement.minHeight || 0;

        // Loops through the creative to get the perfect match for the placement
        for (const enrichedCreative of enrichedCreatives) {
            const enrichedCreativeType = enrichedCreative.type;
            let creativeFormats: Format[] = [];

            if (enrichedCreativeType === 'customUpload') {
                const { width, height } = enrichedCreative.data;

                const currentDiff = getDifference(width, height, placementAspectRatio, placementMinWidth, placementMinHeight);

                // Checks if that difference is less than the previous one
                // If it is  0 it is the perfect match
                if (typeof currentDiff !== 'undefined' && (!closestDiff || currentDiff < closestDiff)) {
                    closestDiff = currentDiff;
                    creative = enrichedCreative;
                }

                continue;
            }

            if (enrichedCreativeType === 'template') creativeFormats = CreativeV2FormatHelpers.getActiveFormats(enrichedCreative);
            // Loops through the formats to get the perfect format for the placement
            creativeFormats.forEach((format) => {
                const { width, height } = format;

                const currentDiff = getDifference(width, height, placementAspectRatio, placementMinWidth, placementMinHeight);

                // Checks if that difference is less than the previous one
                // If it is  0 it is the perfect match
                if (typeof currentDiff !== 'undefined' && (typeof closestDiff === 'undefined' || currentDiff < closestDiff)) {
                    closestDiff = currentDiff;
                    creative = enrichedCreative;
                    creativeFormatKey = format.key;
                }
            });
        }

        return { creative, creativeFormatKey };
    };

    /**
     * Get dimensional difference between creative and placement
     * @param assetWidth
     * @param assetHeight
     * @param placementAspectRatio
     * @param placementMinWidth
     * @param placementMinHeight
     * @returns difference
     */
    const getDifference = (assetWidth = 0, assetHeight = 0, placementAspectRatio: number, placementMinWidth: number, placementMinHeight: number) => {
        // Asset ratio of the asset
        const assetAspectRatio = assetWidth / assetHeight;

        // Checks the format if the min width and the height for a placemnet fits
        if (assetWidth >= placementMinWidth && assetHeight >= placementMinHeight) {
            // Formula to calculate the difference
            const currentDiff = Math.abs(placementAspectRatio - assetAspectRatio);
            return currentDiff;
        }
    };

    /**
     * Format placements in sections by their types
     * @returns Formated placements object
     */
    const getPlacementsInSections = (enrichedCreatives: CreativeV2Enriched[], framePlacements?: AdSetupPlacements): PlacementsSection[] | undefined => {
        if (!brick) return;

        // Platfrom of placements
        const placementSectionsData = BrickHelpers.getBrickData<PlacementsSection[]>(brick.subType, 'placements');

        // Gets selected placements from the parent
        const parentBrick = BrickHelpers.getBrickById(brick.parentId);
        const parentSelectedPlacements: string[] = parentBrick?.data?.settings?.targeting?.placements || [];

        // Placement types from placements object
        // const placementTypes = Object.keys(placementsObject.types);

        const sections: PlacementsSection[] = [];

        // Goes through the placement types and takes the placements for it
        placementSectionsData.forEach((section) => {
            const sectionCopy = { ...section };
            // Goes through all the placements and sorting them by type and
            // and checks if there are any assigned assets to the placement
            const children = section.children.reduce((acc: PlacementItemInterface[], item: Placement) => {
                if (parentSelectedPlacements.length && !parentSelectedPlacements.includes(item.key)) return acc;

                // Item key
                const placementKey = item.key;

                //If the placement item is not assigned with a creative
                if (!framePlacements || !framePlacements[placementKey]) {
                    if (creatives?.length) acc.push({ ...item });
                    else acc.push({ ...item });
                }
                // If the placements item is assigned with a asset add the format name if there is any
                else {
                    const enrichedCreative = enrichedCreatives.find((item) => item.id === framePlacements[placementKey].creativeId);
                    let formatName: string | undefined;
                    const formatKey = framePlacements[placementKey].formatKey;
                    if (enrichedCreative?.type === 'template' && formatKey)
                        formatName = CreativeV2FormatHelpers.getActiveFormat(enrichedCreative, formatKey)?.title;

                    acc.push({ ...item, formatName, creativeTitle: enrichedCreative?.title, creativeUrl: enrichedCreative?.data.url });
                }

                return acc;
            }, []);

            sectionCopy.children = children;
            if (sectionCopy.children.length) sections.push(sectionCopy);
        });

        return sections;
    };

    useEffect(() => {
        (async function () {
            const enrichedCreatives: Array<CreativeV2Enriched> = [];

            if (creatives)
                for (const creative of creatives) {
                    const enrichedCreative = await getCreative(creative);
                    if (enrichedCreative) enrichedCreatives.push(enrichedCreative);
                }

            // Frame assigned placements
            const framePlacements: AdSetupPlacements | undefined = getAssignedAssetsToPlacements(enrichedCreatives);

            // Placements items for the component view
            const placementsData = getPlacementsInSections(enrichedCreatives, framePlacements);

            if (!placementsData) return;

            setPlacementSections(placementsData);

            onChange('placements', framePlacements, true);
        })();
    }, [creatives]);

    return (
        <div className="placements-component">
            {placementSections &&
                placementSections.length &&
                placementSections.map((section: PlacementsSection, id: number) => {
                    return <PlacementSection key={id} section={section} />;
                })}
        </div>
    );
};

export default AdPlacements;
