import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import TextField from 'components/ui-components/TextField';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { MODEL_TITLE } from 'components/bricks/constants';

import './../styles/title.scss';

interface Props {
    brick: Brick;
}

const BriefingTitle = ({ brick }: Props) => {
    const [title, setTitle] = useState<string>(brick.title || '');

    if (!brick) return null;

    const setup = BrickHelpers.getBrickData<BrickSetup>(brick.subType, 'setup');

    const onSaveTitle = (title: string) => {
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_TITLE, title);
    };

    // Only save the new description after the user stopped typing for 300ms
    const debouncedChangeStateUpdate = useCallback(debounce(onSaveTitle, 300), []);

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        debouncedChangeStateUpdate(e.target.value);
    };

    return (
        <div className="briefing-content__title">
            <span className="briefing-content__title__title">Title</span>
            <TextField className="briefing-content__title__input" value={title || setup.title} onChange={onTitleChange} />
        </div>
    );
};

export { BriefingTitle };
