import React, { useCallback, useEffect, useState } from 'react';
import { UserProfile } from 'components/data/UserProfileHelpers/types/user-profile.type';
import UserSelector from 'components/ui-components-cape/UserSelector';
import { UserProfileHelpers } from 'components/data/UserProfileHelpers';
import { Item as UserSelectorItem } from 'components/ui-components-cape/UserSelector/interfaces/Item';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';

import './../styles/user-input.scss';

interface Props {
    /** Title of the user input. */
    title: string;
    /** Placeholder is used when there no user profile. */
    placeholder?: string;
    /** Current user profile. */
    selectedUserId?: string | number;
    /** The model which the data should be saved after selecting an user. */
    model: string;
    /** The current active brick id, this needed in order to save the changes to the correct brick. */
    brickId: string;
}

/** An component for selecting a user from a list of user profiles. */
const UserInput: React.FC<Props> = ({ title, selectedUserId, model, placeholder, brickId }) => {
    const [users, setUsers] = useState<UserSelectorItem[]>([]);

    /**
     * Handles the change event of the UserSelector component.
     * @param value The selected user profiles.
     */
    const handleChange = (value: UserSelectorItem[]) => {
        if (!value) return;

        const usersIds: number[] = value.map((value) => Number(value.id));

        BricksComponentStoreHelper.setBrickModel(brickId, model, usersIds); // Save the selected user profiles to the model.
    };

    /**
     * Returns a list of users with the itemType set to 'user'.
     * @param userProfiles The list of user profiles without the itemType set.
     */
    const getUsersWithItemType = (userProfiles?: UserProfile[]): UserSelectorItem[] => {
        if (!userProfiles) return []; // Return an empty array if there are no user profiles.

        return userProfiles.map((user) => ({ ...user, itemType: 'user' })); // Set the itemType to 'user' for each user profile.
    };

    /**
     * Returns the selected user with the profile, based on the provided user id and the list of user profiles.
     * @param users The list of user profiles.
     * @param userId The user id.
     */
    const getSelectedUserWithProfile = (users: UserSelectorItem[], userId?: string | number) => {
        if (!userId) return;

        return users.find((user) => user.id === String(userId));
    };

    /** This is the selected user if exists else it would be undefined */
    const selectedUser = useCallback(() => getSelectedUserWithProfile(users, selectedUserId), [users, selectedUserId])();

    /**
     * Handles the user profiles and sets the users state.
     * @param profiles The list of user profiles.
     */
    const handleUsersProfiles = async () => {
        const profiles = await UserProfileHelpers.getUserProfiles(); // Fetch the user profiles.
        const users = getUsersWithItemType(profiles); // Set the itemType to 'user' for each user profile.

        setUsers(users); // Set the users state.
    };

    useEffect(() => {
        handleUsersProfiles(); // Fetch the user profiles and set the users state.
    }, []);

    return (
        <div className="user-input">
            <div className="user-input__title">{title}</div>
            <UserSelector onMutation={(value) => handleChange(value)} users={users} value={selectedUser} avatarSize="large" />
            {selectedUser && <span className="user-input__label">{selectedUser?.name}</span>}
            {!selectedUser && <span className="user-input__label">{placeholder}</span>}
        </div>
    );
};

export default UserInput;
