import React from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import RadioList from 'components/input/RadioList';
import Translation from 'components/data/Translation';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import useBrick from 'components/bricks/hooks/useBrick';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { BrickSetup } from 'components/bricks/types/brick.type';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';

type FrameProps = {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
};

type FrameComponent = React.ComponentType<FrameProps>;

const AdSetupType = () => {
    const { brick } = useBrick();

    if (!brick) return;

    const { adSetup, selectedFrame, onChange, changeAdSetup, changeSelectedFrame } = useAdSetupContext();

    // Get the frame components for this brick subType
    const SingleFrame = BrickHelpers.getBrickData<FrameComponent>(brick?.subType, 'adSetupSingleFrame');
    const MultiFrame = BrickHelpers.getBrickData<FrameComponent>(brick?.subType, 'adSetupMultiFrame');
    const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick.subType, 'setup');

    // Check if multi-frame setup is enabled
    const allowsMultiFrame = brickSetup?.config?.adSetup?.allowsMultiFrame;

    /**
     * Select creatives
     * @param creatives
     */
    const handleSelectCreatives = (creatives: CreativeV2[]): void => {
        let allCreativesForBrick: CreativeV2[] = brick.data?.creatives || [];
        allCreativesForBrick = [...allCreativesForBrick, ...creatives];

        const adSetupCopy = { ...adSetup };
        const frameCreativeIds = adSetupCopy.items?.[selectedFrame].creativeIds || [];

        // Check if an item is removed
        if (creatives.length < frameCreativeIds.length) {
            let removedItemIndex = 0;
            let removedCreativeId = '';

            // Checks which item is removed
            frameCreativeIds.forEach((creativeId, index) => {
                if (!creatives[index] || creatives[index].id !== creativeId) {
                    removedCreativeId = creativeId;
                    removedItemIndex = index;
                }
            });
            // Remove it from data.creatives
            allCreativesForBrick = allCreativesForBrick.filter((creative) => creative && creative.id !== removedCreativeId);
            // Removes the item from the current frame
            frameCreativeIds.splice(removedItemIndex, 1);
        } else {
            // Added creative
            const addedCreative = creatives[creatives.length - 1];

            frameCreativeIds.push(addedCreative.id);
        }

        // Set the new creatives in the brick
        if (allCreativesForBrick.length) BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_CREATIVES, allCreativesForBrick);
        else BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_CREATIVES, undefined);

        if (!frameCreativeIds.length) return onChange('creativeIds', undefined, true); // Set creativeIds to undefined if there are no creatives because an empty array will not trigger a re-render.

        onChange('creativeIds', frameCreativeIds, true);
    };

    /**
     * Changes the frame and if it is single it removes the unused frames
     * @param newValue AdSetupFrameType
     */
    const handleChangeFrameType = (newValue: AdSetupFrameType): void => {
        if (newValue === 'multi') return onChange('type', newValue);

        const updatedAdSetup = {
            ...adSetup,
            type: newValue,
            items: adSetup.items?.slice(0, 1) // Keep only the first item
        };

        changeSelectedFrame(0);
        changeAdSetup(updatedAdSetup);
    };

    return (
        <>
            {allowsMultiFrame !== false && (
                <Accordion
                    square={true}
                    title={Translation.get('adSetup.adSetup', 'bricks')}
                    classes={{ root: 'ad-setup__accordion__root', summaryRoot: 'ad-setup__accordion__summary__root' }}>
                    <RadioList
                        onMutation={(newValue: AdSetupFrameType) => handleChangeFrameType(newValue)}
                        value={adSetup.type}
                        options={[
                            { key: 'single', value: Translation.get('adSetup.singleFrame', 'bricks') },
                            { key: 'multi', value: Translation.get('adSetup.multiFrame', 'bricks') }
                        ]}
                    />
                </Accordion>
            )}
            {adSetup.type === 'single' && SingleFrame && <SingleFrame handleSelectCreatives={handleSelectCreatives} />}
            {adSetup.type === 'multi' && MultiFrame && <MultiFrame handleSelectCreatives={handleSelectCreatives} />}
        </>
    );
};

export default AdSetupType;
