import React, { useEffect, useState } from 'react';
import cloneDeep from 'helpers/cloneDeep';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { BricksComponentStore, UnifiedTab } from 'components/bricks/types/bricksComponentStore.type';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import MultiInput from 'components/editor-blocks/MultiInput';
import EditorData from 'components/editor-data/EditorData';
import { EditorLocalScope } from 'components/editor-data/EditorLocalScope';
import { MODEL_TEMP_BRICK } from 'components/bricks/constants';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import useBrick from 'components/bricks/hooks/useBrick';
import BrickHelpers, { IAdditionalVars } from 'components/bricks/helpers/brick.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import { DynamicValueOption } from 'components/input/DynamicData/types/dynamic-value.type';
import MultiInputWrapperHelpers from './helpers';
import Accordion from '../accordion';
import './styles/main.scss';

interface ComponentStoreProps {
    activeItemId: BricksComponentStore['activeItemId'];
    activeTab: BricksComponentStore['activeTab'];
}

type Props = {
    editorBlocks: IEditorBlock[];
    isNewBrick?: boolean;
};

const updateEditorBlocks = (editorBlocks: IEditorBlock[], brick: Brick, tab: UnifiedTab, allDynamicValueOptions: DynamicValueOption[]) => {
    // Remove inputs from the interface setup based on the customer config. A customer can disable inputs if they want through the config
    editorBlocks = MultiInputWrapperHelpers.removeInputs(editorBlocks, brick, tab);

    // Add inputs from to interface setup based on the customer config. A customer can add inputs if they want through the config
    editorBlocks = MultiInputWrapperHelpers.addInputs(editorBlocks, brick, tab);

    // Change the block model of every input based on the tab. It addes the tab key in the block model
    editorBlocks = MultiInputWrapperHelpers.modifyBlockModelByTab(editorBlocks, tab);

    // Assign dynamic value options
    editorBlocks = MultiInputWrapperHelpers.assignDynamicValueOptions(editorBlocks, allDynamicValueOptions);

    return editorBlocks;
};

/**
 * This component is responsible to render the multi input based on the provided editor blocks within a local scope.
 */
const BricksMultiInputBlockWrapper = ({ editorBlocks, isNewBrick }: Props) => {
    const { activeItemId, activeTab } = useComponentStore<ComponentStoreProps>('Bricks', { fields: { activeItemId: 'activeItemId', activeTab: 'activeTab' } });
    const [dynamicValueOptions, setDynamicValueOptions] = useState<DynamicValueOption[]>([]);

    const { brick } = useBrick();

    if (!brick || !editorBlocks.length || !activeTab) return;

    // Get the additional data that can be needed for the conditions of the multi inputs
    // We get the current brick via getBrickById, because we need the most up to date version
    const additionalVars: IAdditionalVars = {
        brick: BrickHelpers.getBrickById(brick.id),
        ...BrickHelpers.getAdditionalBrickVars(brick)
    };

    useEffect(() => {
        const hasDynamicDataInputs = editorBlocks.some((editorBlock: IEditorBlock) => {
            if (editorBlock.items && editorBlock.items.length) return editorBlock.items.some((item) => !item.dynamicValueActive);
            return;
        });

        if (!hasDynamicDataInputs) return;

        MultiInputWrapperHelpers.getAllDynamicValueOptions(brick, activeTab, additionalVars).then((options) => {
            setDynamicValueOptions(options);
        });
    }, [brick, editorBlocks]);

    const updatedEditorBlocks = updateEditorBlocks(cloneDeep(editorBlocks), brick, activeTab, dynamicValueOptions);

    const localScopeUuid = (() => {
        if (!activeItemId || isNewBrick || !brick) {
            return MODEL_TEMP_BRICK;
        }

        return 'i_' + activeItemId;
    })();

    const handleOnChange = (data: unknown) => {
        const activeBrick = BrickHelpers.getBrickById(activeItemId);

        if (!activeItemId || !data || !activeBrick) return;

        BricksComponentStoreHelper.setBrickModel(activeItemId, '', data);
    };

    /**
     * Render the interface blocks based on the page structure
     */
    const renderBlocks = () => {
        const additionalVars = {
            brick,
            ...BrickHelpers.getAdditionalBrickVars(brick)
        };

        return (
            <>
                {updatedEditorBlocks.map((item, index) => {
                    if (!EditorData.validateCondition(item, undefined, undefined, additionalVars)) {
                        return <React.Fragment key={index} />;
                    } else {
                        return (
                            <Accordion key={index} title={item.title} classes={{ root: 'bricks-multi-input-block-wrapper__accordion' }}>
                                <MultiInput key={activeItemId} data={{ ...item, additionalVars }} localScope={localScopeUuid} />
                            </Accordion>
                        );
                    }
                })}
            </>
        );
    };

    return (
        <div className={'bricks-multi-input-block-wrapper'}>
            <EditorLocalScope onChange={handleOnChange} localScopeUuid={localScopeUuid} initialData={brick} renderBlocks={renderBlocks} />
        </div>
    );
};

export default BricksMultiInputBlockWrapper;
