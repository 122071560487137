import React from 'react';
import { PlacementsSection } from 'components/bricks/types/placement.type';
import Accordion from '../../accordion';
import PlacementItem from './ad-placements-item';
import { PlacementItemInterface } from './ad-placements';
import './../styles/ad-placements-section.scss';

interface PlacementSectionProps {
    section: PlacementsSection;
}

const PlacementSection: React.FC<PlacementSectionProps> = ({ section }) => {
    return (
        <div>
            <Accordion square={true} title={section.label} classes={{ summaryRoot: 'placement-section__accordion__summary__root' }}>
                <div className="placement-section__content">
                    {section.children.map((item: PlacementItemInterface, id: number) => {
                        return <PlacementItem item={item} key={id} />;
                    })}
                </div>
            </Accordion>
        </div>
    );
};

export default PlacementSection;
