import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import EditorData from 'components/editor-data/EditorData';
import { DatePicker } from 'components/input/DatePicker/components';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';

import './../styles/date-input.scss';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
/**
 * The default debounce time for the date input.
 * This is used to prevent the date input from updating the model too often.
 */
const DEFAULT_DEBOUNCE_TIME = 300;

interface Props {
    title: string;
    date?: string;
    model: string;
    brickId: string;
}

const DateInput: React.FC<Props> = ({ title, date, model, brickId }) => {
    const [isDateOpen, setIsDateOpen] = React.useState(false);

    /** Handles the date change. */
    const handleDateChange = debounce((newValue: string | null) => {
        const momentDate = moment(newValue, DEFAULT_DATE_FORMAT); // Convert the date to a moment object.

        if (!newValue || !momentDate.isValid()) {
            const currentDate = EditorData.getValueFromModel(model); // Get the current date from the model.

            // Set the model to an empty string, if the currentDate is not empty already.
            if (currentDate) {
                BricksComponentStoreHelper.setBrickModel(brickId, model, '');
            }
            return;
        }

        BricksComponentStoreHelper.setBrickModel(brickId, model, moment(newValue).format(DEFAULT_DATE_FORMAT)); // Update the model with the new date value.

        if (isDateOpen) {
            setIsDateOpen(false); // Close the date picker after the date is selected from the calendar.
        }
    }, DEFAULT_DEBOUNCE_TIME);

    return (
        <div className="date-input">
            <span className="date-input__title">{title}</span>

            <DatePicker
                classes={{ muiDatePicker: 'date-input__picker' }}
                showTimeZone={false}
                value={date}
                onMutation={handleDateChange}
                canBeEmpty
                open={isDateOpen}
                onClose={() => setIsDateOpen(false)}
                textFieldInputProps={{
                    startAdornment: (
                        <IconButton onClick={() => setIsDateOpen(true)} className="date-input__picker__start-adornment">
                            <Icon>event</Icon>
                        </IconButton>
                    )
                }}
            />
        </div>
    );
};

export default DateInput;
