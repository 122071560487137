import React, { useEffect, useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import SocialPreview from 'components/creatives-v2/components/social-previews-v2';
import { Placement } from 'components/bricks/types/placement.type';
import CreativePreviewHelper from '../helpers/creatives-preview.helpers';

import '../styles/content.scss';

// TODO Check these imports
// import useValidations from 'components/bricks/hooks/useValidations';
// import { useAdSetupContext } from '../../ad-setup/context/ad-setup-context';
// import PreviewWarning from './preview-warning';

interface Props {
    brick: Brick;
    activePlacement: Placement | null;
    activeTabKey?: string;
}

const BricksCreativesPreviewContent: React.FC<Props> = ({ brick, activePlacement, activeTabKey }) => {
    const [enrichedCreatives, setEnrichedCreatives] = useState<CreativeV2Enriched[] | undefined>(undefined);

    // const { selectedFrame } = useAdSetupContext();
    // const { warnings } = useValidations([brick.id], activeTabKey, `${brick.subType}-ad_setup-placements-${activePlacement?.key}-${selectedFrame}`);

    useEffect(() => {
        const fetchCreatives = async () => {
            try {
                // Use the new function from CreativeV2Helpers to fetch and enrich creatives
                const enrichedCreatives = await CreativeV2Helpers.getCreativesForBrick(brick);
                setEnrichedCreatives(enrichedCreatives);
            } catch (error) {
                console.error('Error fetching creatives:', error);
            }
        };

        fetchCreatives();
    }, [brick?.data?.creatives?.length, activePlacement?.key]);

    const socialPreviewData = CreativePreviewHelper.generateSocialPreviewData(brick, enrichedCreatives, activePlacement);

    // if (warnings?.length) return <PreviewWarning />; //we might use the warnings display in the future

    return <div className="bricks-creatives-preview-content">{socialPreviewData && <SocialPreview socialPreview={socialPreviewData} />}</div>;
};

export default BricksCreativesPreviewContent;
