import get from 'lodash/get';
import getCustomerConfig from 'components/bricks/data/customerConfig';
import BrickHelpers, { IAdditionalVars } from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import { UnifiedTab } from 'components/bricks/types/bricksComponentStore.type';
import { IEditorBlock, IMultiInput } from 'components/bricks/types/editorBlock.type';
import { DynamicValueOption } from 'components/input/DynamicData/types/dynamic-value.type';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import EditorData from 'components/editor-data/EditorData';

class MultiInputWrapperHelpers {
    /*
     * Deletes inputs from the interface setup based on the customer config. A customer can disable inputs if they want through the config
     * @param editorBlocks The interface setup that needs to be updated
     * @param brick current brick
     * @param tab current tab
     * @returns the updated editor blocks with the removed inputs from the interface setup
     */
    static removeInputs = (editorBlocks: IEditorBlock[], brick: Brick, tab: UnifiedTab) => {
        return editorBlocks.map((editorBlock) => {
            if (!editorBlock.items) return editorBlock;

            // Filter out inputs that should be removed from the interface
            editorBlock.items = editorBlock.items.filter((multiInput) => {
                if (!multiInput.identifier) return true;
                const identifiersToRemove = MultiInputWrapperHelpers.getIdentifiersToRemoveFromInterfaceSetup(brick, tab);
                return !identifiersToRemove.includes(multiInput.identifier);
            });

            return editorBlock;
        });
    };

    /**
     * Adds inputs from the interface setup based on the customer config. A customer can add inputs if they want through the config
     * @param editorBlocks The interface setup that needs to be updated
     * @param brick current brick
     * @param tab current tab
     * @returns the updated editor blocks with the added inputs from the customer config
     */
    static addInputs = (editorBlocks: IEditorBlock[], brick: Brick, tab?: UnifiedTab) => {
        const config = getCustomerConfig();

        config?.bricks?.interfaceSetup?.forEach((item) => {
            if (!MultiInputWrapperHelpers.validateInterfaceUpdate(item, brick, tab)) return;

            if (item.addToInterfaceSetup) {
                item.addToInterfaceSetup.forEach((editorBlock) => {
                    editorBlocks.push(editorBlock);
                });
            }
        });

        return editorBlocks;
    };

    /**
     * Modify block model by
     * @param editorBlocks
     * @returns editor blocks
     */
    static modifyBlockModelByTab = (editorBlocks: IEditorBlock[], tab: UnifiedTab) => {
        return editorBlocks.map((editorBlock: IEditorBlock) => {
            const editorBlockCopy = { ...editorBlock };

            if (tab.key) {
                // Creates new items for the editor block with changed model
                const newEditorBlockItems = editorBlockCopy.items?.map((item) => {
                    // If model is fixed do not change it
                    if (item.fixedModel) return item;

                    // Adds tab key to the model
                    let newModel = tab.key;

                    // Adds block model
                    if (editorBlockCopy.blockModel) {
                        newModel = newModel + '.' + editorBlock.blockModel;
                    }

                    // Adds the item's model
                    if (item.model) newModel = newModel + '.' + item.model;

                    newModel = 'data.' + newModel;

                    return { ...item, model: newModel };
                });

                editorBlockCopy.items = newEditorBlockItems;
            }

            delete editorBlockCopy.blockModel;

            return editorBlockCopy;
        });
    };

    /**
     * Get the config and check if there are any identifiers that should be removed from the interface setup
     * @param brick The brick
     * @returns An array of identifiers that should be removed from the interface setup
     */
    static getIdentifiersToRemoveFromInterfaceSetup = (brick: Brick, tab: UnifiedTab) => {
        const config = getCustomerConfig();
        const identifiersToRemove: string[] = [];

        config?.bricks?.interfaceSetup?.forEach((item) => {
            if (!MultiInputWrapperHelpers.validateInterfaceUpdate(item, brick, tab)) return;

            if (item.removeFromInterfaceSetup) {
                item.removeFromInterfaceSetup.map((identifier) => {
                    identifiersToRemove.push(identifier);
                });
            }
        });

        return identifiersToRemove;
    };

    /**
     * This function checks if the update is applicable to this brick and tab
     * @param item
     * @param brick
     * @param tab
     * @returns
     */
    static validateInterfaceUpdate = (item: any, brick: Brick, tab?: UnifiedTab) => {
        // Don't continue if the brick type (or 'all') is not included in the config
        if (!item.subType.includes(brick.subType) && !item.subType.includes('all')) return false;

        // Don't continue if the tab is not correct
        if (item.tab && tab?.key !== item.tab) return false;

        return true;
    };

    /**
     * Gets the dataset id of parent feed brick
     * @param brick
     * @returns dataset id
     */
    static getDataSetIdOfParentFeedBrick = (brick: Brick): string | undefined => {
        const parentFeedBrick = BrickHelpers.getBrickById(brick.parentId);

        return parentFeedBrick?.data?.dataset?.datasetId;
    };

    /**
     * Disables items based on condition
     * @param brick
     * @returns edited editor blocks
     */
    static disableItemsOnCondition = (editorBlocks: IEditorBlock[], additionalVars: IAdditionalVars): IEditorBlock[] => {
        return editorBlocks.map((editorBlock: IEditorBlock) => {
            const editorBlockCopy = { ...editorBlock };

            editorBlockCopy.items = editorBlockCopy.items?.map((item: IMultiInput) => {
                try {
                    // Condition to disable the item
                    const disableCondition = item.disableCondition;

                    // Condition is false
                    if (!disableCondition || !eval(disableCondition)) return item;

                    // Condition is true
                    return { ...item, readOnly: true };
                } catch (ex) {
                    // Condition is not valid expression
                    return item;
                }
            });

            return editorBlockCopy;
        });
    };

    static getAllDynamicValueOptionsForBrick = (brick: Brick, tab: UnifiedTab, additionalVars: IAdditionalVars, model = 'brick') => {
        let editorBlocks: IEditorBlock[] = BrickHelpers.getBrickData(brick.subType, 'settingsInputs');
        if (!editorBlocks) editorBlocks = BrickHelpers.getBrickData(brick.subType, 'settings');
        if (!editorBlocks) return [];

        const brickSetup = BrickHelpers.getBrickData(brick.subType, 'setup') as BrickSetup;
        const label = brickSetup.title;

        let optionsCopy: DynamicValueOption[] = [];

        editorBlocks.forEach((editorBlock) => {
            const blockModel = editorBlock.blockModel;
            const editorBlockItems = editorBlock.items || [];

            for (const item of editorBlockItems) {
                // Select only the inputs which has value which can be build as dynamic input
                if (!item.type || !['text', 'dynamicData', 'select', 'number', 'switch'].includes(item.type)) continue;

                // Build the model
                let itemModel = model;

                // Continue if the item's condition is not valid
                if (item.condition) {
                    const brickData = brick.data;
                    const blockData = get(brickData, `${tab.key}.${blockModel}`);

                    if (!EditorData.validateCondition(item, blockData, undefined, additionalVars)) continue;
                }

                if (!item.model) continue;
                else if (item.fixedModel) itemModel = `${itemModel}.${item.model}`;
                else {
                    // Puts the data under "data" and the tab key
                    itemModel = itemModel + `.data.${tab.key}`;

                    // Adds block model
                    if (blockModel) {
                        itemModel = `${itemModel}${blockModel}`;
                    }

                    // Adds the item's model
                    if (item.model) itemModel = `${itemModel}.${item.model}`;
                }

                const option: DynamicValueOption = {
                    value: itemModel,
                    label: `${label}: ${item.label}`,
                    identifier: item.identifier || ''
                };

                optionsCopy.push(option);
            }
        });

        const parent = BrickHelpers.getBrickById(brick.parentId);
        if (parent) {
            const nextModel = this.getNextModelForBrickDynamicOption(model);
            const parentDynamicValueOptions = this.getAllDynamicValueOptionsForBrick(parent, tab, additionalVars, nextModel);
            optionsCopy = [...optionsCopy, ...parentDynamicValueOptions];
        }

        return optionsCopy;
    };

    static getAllDynamicValueOptions = async (
        brick: Brick,
        tab: UnifiedTab,
        additionalVars: IAdditionalVars,
        model = 'brick'
    ): Promise<DynamicValueOption[]> => {
        const brickTypeDynamicValueOptions = this.getAllDynamicValueOptionsForBrick(brick, tab, additionalVars, model);

        let options = [...brickTypeDynamicValueOptions];

        if (additionalVars.datasetId) {
            const feedColumnOptions = (await this.loadFeedColumnsAsDynamicValueOptions(additionalVars.datasetId as string)) || [];
            options = [...options, ...feedColumnOptions];
        }

        return options;
    };

    static getNextModelForBrickDynamicOption = (currentModel: string) => {
        switch (currentModel) {
            case 'brick':
                return 'parent';
            case 'parent':
                return 'parent.parent';
            default:
                return '';
        }
    };

    static assignDynamicValueOptions = (editorBlocks: IEditorBlock[], allDynamicValueOptions: DynamicValueOption[]): IEditorBlock[] => {
        return editorBlocks.map((editorBlock: IEditorBlock) => {
            if (editorBlock.items && editorBlock.items.length)
                editorBlock.items = editorBlock.items.map((item) => {
                    if (!item.dynamicValueActive) return item;

                    const filteredDynamicValueOptions = allDynamicValueOptions.filter((option) => option.identifier !== item.identifier);
                    item.dynamicValueOptions = [...filteredDynamicValueOptions];
                    return item;
                });
            return editorBlock;
        });
    };

    static loadFeedColumnsAsDynamicValueOptions = async (datasetId?: string): Promise<DynamicValueOption[] | undefined> => {
        if (!datasetId) return;
        const response = await FeedRequest.get(`dataset/${datasetId}`);
        const data = response.data;
        if (data && data.structure) {
            return Object.keys(data.structure).map((column) => {
                return {
                    value: `item.${column}`,
                    label: `Feed: ${column}`
                };
            });
        }
    };
}

export default MultiInputWrapperHelpers;
