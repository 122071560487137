import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import { MODEL_BRIEFING_DESCRIPTION } from 'components/bricks/constants';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import RichTextEditor from 'components/input/RichTextEditor';
import './../styles/description.scss';

interface Props {
    brick: Brick;
}

const BriefingDescription = ({ brick }: Props) => {
    const [description, setDescription] = useState<string>(brick.data?.briefing?.description || '');

    const onSaveDescription = (description: string) => {
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_BRIEFING_DESCRIPTION, description);
    };

    // Only save the new description after the user stopped typing for 300ms
    const debouncedChangeStateUpdate = useCallback(debounce(onSaveDescription, 300), []);

    const onDescriptionChange = (value: any) => {
        setDescription(value);
        debouncedChangeStateUpdate(value);
    };

    return (
        <div className="briefing-content__description">
            <span className="briefing-content__description__title">{Translation.get('labels.briefing', 'common')}</span>
            <RichTextEditor value={description} onMutation={onDescriptionChange} />
        </div>
    );
};

export { BriefingDescription };
