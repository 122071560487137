import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import { Brick } from 'components/bricks/types/brick.type';
import { getCreativeFromBrick, getCustomSeverity } from 'components/bricks/components/shared/components/creative-input/helpers/asset.helpers';
import Translation from 'components/data/Translation';
import RestrictionAlertDialog from '../creative-input/components/alert-dialog';
import defaultAssetRestrictionKeys from '../creative-input/data/default-asset-validation-results';

import './styles/index.scss';

interface Props {
    brick: Brick;
    classes?: { root?: string };
}

/** Asset warning message component. */
const AssetWarningMessage: React.FC<Props> = ({ brick, classes }) => {
    const assetSeverity = getCustomSeverity(brick);
    const [showDialog, setShowDialog] = useState(false);

    const presetTitle = (() => {
        const creative = getCreativeFromBrick(brick);

        if (assetSeverity === 'flag' && creative) return Translation.get('notBriefed', 'bricks'); // Return the flagged translation if the asset is flagged.

        if (brick.data && brick.data.presets && brick.data.presets.length) {
            return brick.data.presets[0].title;
        }
    })();

    return (
        <>
            <div
                onClick={() => setShowDialog(true)}
                className={classNames('asset-warning-message', classes?.root, {
                    'asset-warning-message--success': assetSeverity === 'success',
                    'asset-warning-message--error': assetSeverity === 'error',
                    'asset-warning-message--warning': assetSeverity === 'warning',
                    'asset-warning-message--info': assetSeverity === 'info',
                    'asset-warning-message--flag': assetSeverity === 'flag'
                })}>
                {presetTitle && (
                    <div
                        className={classNames('asset-warning-message__icon', {
                            'asset-warning-message__icon--success': assetSeverity === 'success',
                            'asset-warning-message__icon--error': assetSeverity === 'error',
                            'asset-warning-message__icon--warning': assetSeverity === 'warning',
                            'asset-warning-message__icon--info': assetSeverity === 'info',
                            'asset-warning-message__icon--flag': assetSeverity === 'flag'
                        })}>
                        <Icon className="asset-warning-message__icon__root">
                            {(() => {
                                if (assetSeverity === 'success') return 'check_circle_outline';
                                if (assetSeverity === 'error') return 'error_outline';
                                if (assetSeverity === 'warning') return 'warning';
                                if (assetSeverity === 'info') return 'info_outline';
                                if (assetSeverity === 'flag') return 'outlined_flag';
                            })()}
                        </Icon>
                    </div>
                )}

                {presetTitle && <div className="asset-warning-message__title">{presetTitle}</div>}
            </div>
            {showDialog && (
                <RestrictionAlertDialog
                    brick={brick}
                    severity={'info'}
                    onClose={() => setShowDialog(false)}
                    defaultAssetRestrictionKeys={defaultAssetRestrictionKeys}
                />
            )}
        </>
    );
};

export default AssetWarningMessage;
