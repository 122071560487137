import React from 'react';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { ValidationResult } from 'components/bricks/types/validator.type';
import TooltipContent from '../ad-setup/components/alert-tooltip-content';

import './styles/main.scss';

export type DotIndicatorSeverity = 'success' | 'warning' | 'error' | 'default';

export interface TooltipValidationResults {
    errors?: ValidationResult[];
    warnings?: ValidationResult[];
    success?: ValidationResult[];
    info?: ValidationResult[];
}

interface Props {
    severity?: DotIndicatorSeverity;
    tooltip?: TooltipValidationResults;
    disableTooltipHover?: boolean;
}

/**
 * This component is responsible for rendering a dot indicator with a specific severity.
 */
const DotIndicator: React.FC<Props> = ({ severity = 'default', tooltip, disableTooltipHover = false }) => {
    return (
        <Tooltip
            title={tooltip && <TooltipContent errors={tooltip?.errors} warnings={tooltip?.warnings} success={tooltip?.success} info={tooltip?.info} />}
            disableHoverListener={severity === 'default' || disableTooltipHover}>
            <span
                className={classNames('bricks-dot-indicator', {
                    'bricks-dot-indicator__success': severity === 'success',
                    'bricks-dot-indicator__warning': severity === 'warning',
                    'bricks-dot-indicator__error': severity === 'error'
                })}
            />
        </Tooltip>
    );
};

export default DotIndicator;
