import React, { useMemo, useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import OutputJobsDialog from 'components/bricks/components/publish/components/output-jobs-dialog';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';

interface Props {
    bricks: Brick[];
    variant?: 'contained' | 'outlined' | 'text';
    onStartDownload?: () => void;
    onFinishDownload?: (publishJobs: BrickPublishJobData[]) => void;
    className?: string;
}
const DownloadButton: React.FC<Props> = ({ bricks, onStartDownload, onFinishDownload, variant = 'outlined', className }) => {
    const [publishDialogOpen, setPublishDialogOpen] = useState(false);
    const brickIds = useMemo(() => bricks.map((brick) => brick.id), [bricks]);

    const handleClickDownloadButton = () => {
        setPublishDialogOpen(true);
    };

    return (
        <div className={`${className}`}>
            <Button size="small" fullWidth variant={variant} onClick={() => handleClickDownloadButton()}>
                {Translation.get('output.download.title', 'bricks')}
            </Button>
            {publishDialogOpen && (
                <OutputJobsDialog
                    brickIds={brickIds}
                    onClose={() => setPublishDialogOpen(false)}
                    onActionFinish={onFinishDownload}
                    onActionStart={onStartDownload}
                    outputAction={'download'}
                />
            )}
        </div>
    );
};

export default DownloadButton;
