import { AlertColor } from '@mui/material';
import get from 'lodash/get';
import ImageFileService from 'services/image-file/image.service';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_CREATIVES, MODEL_PRESETS } from 'components/bricks/constants';
import { Brick, FileType } from 'components/bricks/types/brick.type';
import { CreativeV2CustomUpload } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { ValidationResults } from 'components/bricks/helpers/asset.helpers';
import { AssetRestrictionsKeys, Preset } from 'components/bricks/types/preset';
import LWFiles from 'components/data/Files';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { AssetValidationResult } from '../components/alert-dialog';

/**
 * Gets the severity based on the validation results.
 * @param brick The brick for which to retrieve the severity.
 * @returns The severity for the brick.
 */
const getSeverity = (brick: Brick): AlertColor | undefined => {
    const creatives: CreativeV2CustomUpload[] | undefined = get(brick, MODEL_CREATIVES);
    const results: ValidationResults | undefined = get(brick, MODEL_ASSET_VALIDATION_RESULTS);
    const { errors, warnings } = results ?? {};

    if (!creatives || creatives.length <= 0) return 'info';
    if (errors && errors.length > 0) return 'error';
    if (warnings && warnings.length > 0) return 'warning';

    return 'success';
};

/**
 * Retrieves the custom severity for a given brick.
 * If the parent brick has presets and the current brick doesn't have any, it returns 'flag'.
 * Otherwise, it returns the severity based on the validation results.
 * @param brick - The brick for which to retrieve the custom severity.
 * @returns The severity for the brick.
 */
const getCustomSeverity = (brick: Brick): AlertColor | 'flag' | undefined => {
    const currentBrickPresets: Preset[] | undefined = get(brick, MODEL_PRESETS);
    const parentBrick = BrickHelpers.getBrickById(brick.parentId);
    const parentPresets = parentBrick ? BrickHelpers.getBrickPresetsFromChildren(parentBrick) : [];

    // If the parent brick has presets and the current brick doesn't have any, show a flag.
    if (parentBrick && parentPresets && parentPresets?.length > 0 && (!currentBrickPresets || currentBrickPresets.length === 0)) return 'flag';
    return getSeverity(brick);
};

/**
 * Gets the severity based on the validation results and the restriction type.
 * @param restriction Restriction type to get the severity for.
 * @param creatives The current creatives to get the severity for.
 * @param validationResults The validation results.
 * @returns The severity based on the validation results and the restriction type.
 */
const getSeverityByValidationResults = (
    restriction: AssetRestrictionsKeys,
    value: AssetValidationResult['value'],
    validationResults?: ValidationResults
): AlertColor => {
    const { errors, warnings } = validationResults ?? {}; // Get the errors and warnings from the validation results.
    const resultHasError = errors?.find((error) => error.type === restriction); // Check if the result has an error based on the restriction type.
    const resultHasWarning = warnings?.find((warning) => warning.type === restriction); // Check if the result has a warning based on the restriction type.

    if (!value) return 'info';
    if (resultHasError) return 'error';
    if (resultHasWarning) return 'warning';
    return 'success';
};

/**
 * Makes sure the file type is valid based on the supported file types.
 * For example if fileType is 'application' it will return 'zip'.
 * @param fileType File type to validate.
 * @returns Valid file type.
 */
const getValidFileType = (fileType: string): FileType => {
    if (fileType === 'application') {
        return 'zip'; // Zip files are considered as applications.
    }

    return fileType as FileType;
};

/**
 * Gets the first creative from the provided brick.
 * @param brick Brick to get the creative from.
 * @returns The first creative from the brick.
 */
const getCreativeFromBrick = (brick?: Brick): CreativeV2CustomUpload | undefined => {
    const creatives: CreativeV2CustomUpload[] | undefined = get(brick, MODEL_CREATIVES);

    if (!creatives || creatives.length <= 0) return;

    return creatives[0];
};

/**
 * Converts the max file size to human readable format.
 * @example 30000KB will be converted to 30MB.
 * @param maxFileSizeInKb The max file size in kilobytes
 * @returns The max file size in human readable format.
 */
const convertMaxFileSizeToHumanReadable = (maxFileSizeInKb: number): string => {
    const maxFileSizeInBytes = ImageFileService.convertKiloBytesToBytes(maxFileSizeInKb);
    return LWFiles.humanReadableSize(maxFileSizeInBytes);
};

/**
 * Rounds the seconds to the specified decimal places.
 * @param seconds The seconds to round.
 * @param decimalPlaces The decimal places to round to, the default is 2.
 */
const roundSeconds = (seconds: number, decimalPlaces = 2): number => {
    return Number(seconds.toFixed(decimalPlaces)); // Round the value to 2 decimal places.
};

export {
    getSeverity,
    getValidFileType,
    getCreativeFromBrick,
    getSeverityByValidationResults,
    convertMaxFileSizeToHumanReadable,
    roundSeconds,
    getCustomSeverity
};
