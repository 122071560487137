import React, { useState } from 'react';
import AlertTitle from 'components/ui-components-v2/AlertTitle';
import Alert from 'components/ui-components-v2/Alert';
import Button from 'components/ui-components-v2/Button';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import FileHelper from 'components/data/Files';
import RestrictionAlertDialog from './alert-dialog';
import { AssetRestrictions, AssetRestrictionsKeys } from '../../../../../types/preset';
import { getSeverity } from '../helpers/asset.helpers';
import { restrictionDataMap } from '../data/restriction-data-map';
import { getFileExtensionName } from '../../presets-dialog/utils/table-utils';

interface Props {
    brick: Brick;
}

const RestrictionAlert = ({ brick }: Props) => {
    const [showDialog, setShowDialog] = useState(false);

    const severity = getSeverity(brick);

    const getTitle = () => {
        const presetTitle = brick.data?.presets?.[0]?.title;
        return presetTitle ? presetTitle : Translation.get('fileRestrictions', 'bricks');
    };

    const getDescription = () => {
        const restrictions: AssetRestrictions | undefined = brick.data?.presets?.[0]?.restrictions;
        let description = '';

        if (!restrictions) return '';

        (Object.keys(restrictions) as AssetRestrictionsKeys[]).forEach((restriction) => {
            const { message, unit } = restrictionDataMap[restriction];
            let restrictionValue = restrictions[restriction];

            if (restriction === 'aspectRatioPercentageTolerance') return;

            if (restriction === 'fileExtension') {
                restrictionValue = getFileExtensionName(restrictionValue as any);
            }

            if (restriction === 'maxSizeKb') {
                restrictionValue = FileHelper.humanReadableSize(restrictionValue);
            }

            description += `${message}: ${restrictionValue}${unit ?? ''} | `;
        });

        return description.slice(0, -2); // Remove the last |
    };

    return (
        <>
            <Alert
                severity={severity}
                action={
                    <Button color="inherit" size="small" style={{ minWidth: 100 }} onClick={() => setShowDialog(true)}>
                        <b>{Translation.get('actions.moreDetails', 'common')}</b>
                    </Button>
                }>
                <AlertTitle>{getTitle()}</AlertTitle>
                {getDescription()}
            </Alert>

            {showDialog && <RestrictionAlertDialog brick={brick} severity={severity} onClose={() => setShowDialog(false)} />}
        </>
    );
};

export default RestrictionAlert;
