import React from 'react';
import classNames from 'classnames';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import Setup from 'components/data/Setup';
import { FileType } from 'components/bricks/types/brick.type';
import FileUpload from 'components/data/FileUpload';
import DynamicAssetV2 from 'components/creatives-v2/components/dynamic-asset-v2';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import Loader from 'components/ui-components/Loader';

import '../styles/bricks-media-input.scss';
import User from 'components/data/User';

interface Classes {
    root?: string;
}
interface Props {
    fileType: FileType | FileType[];
    preview?: string;
    value?: CreativeV2Enriched;
    onHandleAssetMutation: (assetData: SourceData, dataType?: string) => void;
    showAssetGalleryDialog?: boolean;
    setShowAssetGalleryDialog?: (show: boolean) => void;
    isLoading?: boolean;
    classes?: Classes;
}

const BricksMediaInput = ({
    preview,
    fileType,
    value,
    onHandleAssetMutation,
    showAssetGalleryDialog = false,
    setShowAssetGalleryDialog,
    isLoading = false,
    classes
}: Props) => {
    return (
        <div className={classNames('bricks-media-input', classes?.root)}>
            <Loader isLoading={isLoading} classes={{ root: 'bricks-media-input__loader-root', loaderBox: 'bricks-media-input__loader-box' }}>
                {!preview && User.hasRight('brickManagement') && (
                    <FileUpload
                        fileType={fileType}
                        onUploadComplete={(data) => {
                            onHandleAssetMutation(data[0]);
                        }}
                        display="bricks"
                        classes={{ root: 'bricks-media-input__file-upload', fileBox: 'bricks-media-input__file-upload__file-box' }}
                    />
                )}
                {preview && value && (
                    <div className="bricks-media-input__container-asset">
                        {
                            <DynamicAssetV2
                                classes={{
                                    image: 'bricks-media-input__container-asset__image',
                                    video: 'bricks-media-input__container-asset__video',
                                    audio: 'bricks-media-input__container-asset__audio'
                                }}
                                creative={value}
                            />
                        }
                    </div>
                )}
            </Loader>

            <AssetGalleryDialog
                open={showAssetGalleryDialog}
                canUseContentSpace={Setup.hasModule('assetLibrary')}
                useCropper
                multiple={false}
                canSkipCropper
                selectors={['upload', 'contentSpace']}
                fileType={fileType}
                onMutation={onHandleAssetMutation}
                value={{ ...value }}
                onClose={() => setShowAssetGalleryDialog?.(false)}
            />
        </div>
    );
};

export { BricksMediaInput };
